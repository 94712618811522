// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/apps/user/list'))
const UserView = lazy(() => import('../../views/apps/user/view'))
const Kanban = lazy(() => import('../../views/apps/kanban'))
const CompanyList = lazy(() => import('../../views/apps/company/list'))
const CompanyView = lazy(() => import('../../views/apps/company/view'))
const AgreementList = lazy(() => import('../../views/apps/agreement/list'))
const AgreementView = lazy(() => import('../../views/apps/agreement/view'))
const ProductList = lazy(() => import('../../views/apps/product/list'))
const ProductView = lazy(() => import('../../views/apps/product/view'))
const ProjectList = lazy(() => import('../../views/apps/project/list'))
const ProjectView = lazy(() => import('../../views/apps/project/view'))
const ProposalList = lazy(() => import('../../views/apps/proposal/list'))
const AlertList = lazy(() => import('../../views/apps/alerts/list'))
const TicketList = lazy(() => import('../../views/apps/tickets/list'))
const TicketView = lazy(() => import('../../views/apps/tickets/view'))
const UsersList = lazy(() => import('../../views/apps/usersList/list'))
const AuditList = lazy(() => import('../../views/apps/auditlog/list'))
const Roles = lazy(() => import('../../views/apps/roles-permissions/roles'))
const RoleView = lazy(() => import('../../views/apps/roles-permissions/roles/view'))
const Permissions = lazy(() => import('../../views/apps/roles-permissions/permissions'))
const Calendar = lazy(() => import('../../views/apps/calendar'))
const DocumentationList = lazy(() => import('../../views/apps/documentation/list'))

const InvoiceAdd = lazy(() => import('../../views/apps/invoice/add'))
const InvoiceList = lazy(() => import('../../views/apps/invoice/list'))
const InvoiceEdit = lazy(() => import('../../views/apps/invoice/edit'))
const InvoicePrint = lazy(() => import('../../views/apps/invoice/print'))
const InvoicePreview = lazy(() => import('../../views/apps/invoice/preview'))

const AppRoutes = [
  {
    element: <UserList />,
    path: '/apps/user/list',
    meta: {
      action: 'read',
      resource: 'CONTACTS'
    }
  },
  {
    element: <UserView />,
    path: '/apps/user/view/:id',
    meta: {
      action: 'read',
      resource: 'CONTACTS'
    }
  },
  {
    element: <DocumentationList />,
    path: '/apps/documentation',
    meta: {
      action: 'read',
      resource: 'DOCUMENTATION'
    }
  },
  {
    element: <CompanyList />,
    path: '/apps/company/list',
    meta: {
      action: 'read',
      resource: 'COMPANIES'
    }
  },
  {
    element: <CompanyView />,
    path: '/apps/company/view/:id',
    meta: {
      action: 'read',
      resource: 'COMPANIES'
    }
  },
  {
    element: <AgreementList />,
    path: '/apps/agreement/list',
    meta: {
      action: 'read',
      resource: 'AGREEMENTS'
    }
  },
  {
    element: <AgreementView />,
    path: '/apps/agreement/view/:id',
    meta: {
      action: 'read',
      resource: 'AGREEMENTS'
    }
  },
  {
    element: <AuditList />,
    path: '/apps/auditlog/list',
    meta: {
      action: 'read',
      resource: 'AUDITLOG'
    }
  },
  {
    element: <Calendar />,
    path: '/apps/calendar',
    meta: {
      action: 'read',
      resource: 'SCHEDULING'
    }
  },
  {
    element: <AlertList />,
    path: '/apps/alerts',
    meta: {
      action: 'read',
      resource: 'PUBLIC'
    }
  },
  {
    element: <TicketList />,
    path: '/apps/tickets',
    meta: {
      action: 'read',
      resource: 'TICKETS'
    }
  },
  {
    element: <TicketView />,
    path: '/apps/tickets/view/:id',
    meta: {
      action: 'read',
      resource: 'TICKETS'
    }
  },
  {
    element: <ProductList />,
    path: '/apps/product/list',
    meta: {
      action: 'read',
      resource: 'PRODUCTS'
    }
  },
  {
    element: <ProductView />,
    path: '/apps/product/view/:id',
    meta: {
      action: 'read',
      resource: 'PRODUCTS'
    }
  },
  {
    element: <ProjectList />,
    path: '/apps/projects/list',
    meta: {
      action: 'read',
      resource: 'PROJECTS'
    }
  },
  {
    element: <ProjectView />,
    path: '/apps/projects/view/:id',
    meta: {
      action: 'read',
      resource: 'PROJECTS'
    }
  },
  {
    element: <ProposalList />,
    path: '/apps/proposals',
    meta: {
      action: 'read',
      resource: 'PROPOSALS'
    }
  },
  {
    element: <InvoiceList />,
    path: '/apps/invoice/list',
    meta: {
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    element: <InvoicePreview />,
    path: '/apps/invoice/preview/:id',
    meta: {
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    path: '/apps/invoice/preview',
    element: <Navigate to='/apps/invoice/preview/4987' />,
    meta: {
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    element: <InvoiceEdit />,
    path: '/apps/invoice/edit/:id',
    meta: {
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    path: '/apps/invoice/edit',
    element: <Navigate to='/apps/invoice/edit/4987' />,
    meta: {
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    element: <InvoiceAdd />,
    path: '/apps/invoice/add',
    meta: {
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    path: '/apps/invoice/print/:id',
    element: <InvoicePrint />,
    meta: {
      layout: 'blank',
      action: 'read',
      resource: 'INVOICES'
    }
  },
  {
    element: <UsersList />,
    path: '/apps/users/list',
    meta: {
      action: 'read',
      resource: 'CONTACTS'
    }
  },
  {
    element: <Roles />,
    path: '/apps/roles',
    meta: {
      action: 'read',
      resource: 'PERMISSIONS'
    }
  },
  {
    element: <RoleView />,
    path: '/apps/roles/:id',
    meta: {
      action: 'read',
      resource: 'PERMISSIONS'
    }
  },
  {
    element: <Permissions />,
    path: '/apps/permissions',
    meta: {
      action: 'read',
      resource: 'PERMISSIONS'
    }
  }
]

export default AppRoutes
