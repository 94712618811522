// ** React Imports
import { lazy } from 'react'

const LoginCover = lazy(() => import('../../views/pages/authentication/Login'))
const RegisterBasic = lazy(() => import('../../views/pages/authentication/RegisterCover'))
const ForgotPasswordBasic = lazy(() => import('../../views/pages/authentication/ForgotPasswordBasic'))
const ResetPasswordCover = lazy(() => import('../../views/pages/authentication/ResetPasswordCover'))
const TwoStepsBasic = lazy(() => import('../../views/pages/authentication/TwoStepsCover'))
const AccountSetup = lazy(() => import('../../views/pages/authentication/AccountSetup'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <LoginCover />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/two-step',
    element: <TwoStepsBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/register',
    element: <RegisterBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordBasic />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPasswordCover />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/setup-account/:id',
    element: <AccountSetup />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  }
]

export default AuthenticationRoutes
