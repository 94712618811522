// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get(`${baseUrl}v1/ntiva/contacts`)
  return response.data.data
})

export const getData = createAsyncThunk('appUsers/getData', async (params)  => {
  const orderby = `${params.sortColumn} ${params.sort}`
  const response = await axios.get(`${baseAuthUrl}api/v1/company/${params.id}/contacts?searchTerm=${params.q}&pageSize=${params.perPage}&pageNum=${params.page}&orderby=${orderby}`)
  const data = response.data.data
  const total = response.data.pagination.last
  // const pageNum = parseInt(params.page) - 1
  // const skipData = pageNum * params.perPage
  // const size = params.perPage
  const contacts = data
  // for (let index = 0; index < data.length; index++) {
  //   const contact = data[index]
  //   if (index >= skipData && index < (skipData + size)) {
  //     contacts.push(contact)
  //   }
  // }

  return {
    params,
    data: contacts,
    totalPages: total,
    allData: data
  }
}
)

export const getUserDetails = createAsyncThunk('appUsers/getUserDetails', async id => {
  const response = await axios.get(`${baseUrl}v1/contact/${id}`, { id })
  return response.data.data
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id, { dispatch }) => {
  const response = await axios.get(`${baseAuthUrl}api/v1/contacts/${id}`, { id })
  dispatch(getUserDetails(id))
  return response.data.data
})

export const getTickets = createAsyncThunk('appUsers/getTickets', async (params) => {
  const response = await axios.get(`${baseAuthUrl}api/v1/company/${params.companyId}/tickets?conditions=contact/id=${params.contactId}%20and%20company/id=${params.companyId}%20and%20closedFlag=false`)
  return response.data.data
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { dispatch }) => {
  const response = await axios.patch(`${baseAuthUrl}api/v1/contacts`, user)
  dispatch(getUser(user.contactId))
  return response
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user) => {
  const response = await axios.post(`${baseUrl}v1/ntiva/contacts`, user)
  return response
})
export const importUsers = createAsyncThunk('appUsers/importUsers', async (users) => {
  const response = await axios.post(`${baseUrl}v1/ntiva/contacts/bulk`, users)
  return response
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const getCompanies = createAsyncThunk('appUsers/getCompanies', async () => {
  const response = await axios.get(`${baseUrl}v1/ntiva/companies`)
  return response
})

export const agreementEnabler = createAsyncThunk('appUsers/agreementEnabler', async (configuration, { dispatch }) => {
  const response = await axios.post(`${baseUrl}v1/configuration`, configuration)
  await dispatch(getUser(configuration.id))
  return response
})

export const agreementsListing = createAsyncThunk('appUsers/agreementsListing', async (object) => {
  const response = await axios.post(`${baseUrl}v1/ntiva/contacts/agreements`, object)
  return response.data
})
export const cancelAgreementsListing = createAsyncThunk('appUsers/cancelAgreementsListing', async (object) => {
  const response = await axios.post(`${baseUrl}v1/ntiva/contacts/agreements/cancel`, object)
  if (response.data.data) return response.data
})
export const clearData = createAsyncThunk('appUsers/clearData',  () => {
  return null
})
export const loading = createAsyncThunk(
  "appUsers/loading", (payload) => {
    return payload
  }
)
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    tickets: [],
    companies: [],
    userData: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.selectedUser.agreements = action.payload.agreements
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companies = action.payload
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.userData = action.payload
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.tickets = action.payload
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.data = []
        state.total = 1
        state.allData = []
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })

  }
})

export default appUsersSlice.reducer
