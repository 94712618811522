import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  companies: [
    {
      id: 1,
      name: 'Company 1',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 2,
      name: 'Company 2',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 3,
      name: 'Company 3',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 4,
      name: 'Company 4',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 5,
      name: 'Company 5',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 6,
      name: 'Company 6',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 7,
      name: 'Company 7',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 8,
      name: 'Company 8',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 9,
      name: 'Company 9',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 10,
      name: 'Company 10',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 11,
      name: 'Company 11',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 12,
      name: 'Company 12',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/companies/list/all-data').reply(200, data.companies)

// POST: Add new company
mock.onPost('/apps/company/add-company').reply(config => {
  // Get event from post data
  const company = JSON.parse(config.data)
  const highestValue = data.companies.reduce((a, b) => (a.id > b.id ? a : b)).id

  company.id = highestValue + 1

  data.companies.push(company)

  return [201, { company }]
})

// GET Updated DATA
mock.onGet('/api/companies/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.companies.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    company =>
      (company.email.toLowerCase().includes(queryLowered) ||
        company.fullName.toLowerCase().includes(queryLowered) ||
        company.billing.toLowerCase().includes(queryLowered)) &&
      company.role === (role || company.role) &&
      company.currentPlan === (currentPlan || company.currentPlan) &&
      company.status === (status || company.status)
  )
  /* eslint-enable  */


  return [
    200,
    {
      total: filteredData.length,
      companies: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/companies/company').reply(config => {
  const { id } = config
  const company = data.companies.find(i => i.id === id)
  return [200, { company }]
})

// DELETE: Deletes Company
mock.onDelete('/apps/company/delete').reply(config => {
  // Get company id from URL
  let companyId = config.id

  // Convert Id to number
  companyId = Number(companyId)

  const companyIndex = data.companies.findIndex(t => t.id === companyId)
  data.companies.splice(companyIndex, 1)

  return [200]
})
