// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseWebhookUrl = `${process.env.REACT_APP_API_BASE_URL}webhook/`
export const getAllData = createAsyncThunk('appAgreements/getAllData', async () => {
  const response = await axios.get(`${baseUrl}v1/ntiva/agreements`)
  return response.data
})

export const getData = createAsyncThunk('appAgreements/getData', async params => {
  const response = await axios.get(`${baseUrl}v1/ntiva/agreements?sort=${params.sort}&&sortColumn=${params.sortColumn}`, params)
  const data = response.data.data
  const total = response.data.data.length
  const pageNum = parseInt(params.page) - 1
  const skipData = pageNum * params.perPage
  const size = params.perPage
  const agreements = []
  for (let index = 0; index < data.length; index++) {
    const agreement = data[index]
    if (index >= skipData && index < (skipData + size)) {
      agreements.push(agreement)
    }
  }

  return {
    params,
    data: agreements,
    totalPages: total,
    allData: data
  }
})

export const getAgreement = createAsyncThunk('appAgreements/getAgreement', async id => {
  const response = await axios.get(`${baseUrl}v1/ntiva/agreement/${id}`, { id })
  return response.data.data
})

export const addAgreement = createAsyncThunk('appAgreements/addAgreement', async (agreement, { dispatch, getState }) => {
  await axios.post('/apps/agreement/add-agreement', agreement)
  await dispatch(getData(getState().agreements.params))
  await dispatch(getAllData())
  return agreement
})

export const deleteAgreement = createAsyncThunk('appAgreements/deleteAgreement', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/agreement/delete', { id })
  await dispatch(getData(getState().agreements.params))
  await dispatch(getAllData())
  return id
})

export const agreementEnabler = createAsyncThunk('appUsers/agreementEnabler', async(configuration, { dispatch }) => {
  const response = await axios.post(`${baseUrl}v1/configuration`, configuration)
  await dispatch(getAgreement(configuration.agreementID))
  return response
})
export const importAgreements = createAsyncThunk('appUsers/importAgreements', async(payload, { dispatch }) => {
  const response = await axios.post(`${baseWebhookUrl}v1/agreements/bulk`, payload)
  await dispatch(getAllData())
  return response
})
export const clearData = createAsyncThunk('appAgreements/clearData',  () => {
  return null
})
export const loading = createAsyncThunk(
  "appAgreements/loading", (payload) => {
    return payload
  }
)


export const appAgreementsSlice = createSlice({
  name: 'appAgreements',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAgreement: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getAgreement.fulfilled, (state, action) => {
        state.selectedAgreement = action.payload
        state.isLoading = false
        
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedAgreement = action.payload
        state.data = []
        state.total = 1
        state.allData = []
        
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appAgreementsSlice.reducer
