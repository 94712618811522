// ** Logo
import themeConfig from '@configs/themeConfig'
import { useSkin } from '@hooks/useSkin'
const SpinnerComponent = () => {
  const { skin } = useSkin()
  return (
    <div className='fallback-spinner app-loader'>
      <img className='fallback-logo' src={skin === 'light' ? themeConfig.app.appLogoImage : themeConfig.app.darkLogoImage} style={{maxWidth: "300px"}} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
