// ** React Imports
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

// ** Icons Imports
import { Disc, X, Circle, Menu, Maximize2 } from 'react-feather'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Utils
import { useSkin } from '@hooks/useSkin'

const VerticalMenuHeader = props => {
  const { skin } = useSkin()

  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Menu
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Maximize2
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item me-auto'>
          <span className='navbar-brand'>
            <span className='brand-logo'>
              <img src={skin === 'light' ? themeConfig.app.appLogoImage : themeConfig.app.darkLogoImage} alt='Ntiva' className='desktop-logo' />
              <img src={skin === 'light' ? themeConfig.app.mobileLogoImage : themeConfig.app.darkMobileLogoImage} alt='Ntiva' className='mobile-logo' />
            </span>
          </span>
          
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <Toggler />
            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
