// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from '@src/views/apps/user/store'
import companies from '@src/views/apps/company/store'
import agreements from '@src/views/apps/agreement/store'
import kanban from '@src/views/apps/kanban/store'
import dataTables from '@src/views/tables/data-tables/store'
import authentication from '@src/views/pages/authentication/store'
import accountSetting from '@src/views/pages/account-settings/store'
import calendar from '@src/views/apps/calendar/store'
import projects from '@src/views/apps/project/store'
import proposals from '@src/views/apps/proposal/store'
import documentation from '@src/views/apps/documentation/store'
import tickets from '@src/views/apps/tickets/store'
import alerts from '@src/views/apps/alerts/store'
import products from '@src/views/apps/product/store'
import invoice from '@src/views/apps/invoice/store'
import usersList from '@src/views/apps/usersList/store'
import permissions from '@src/views/apps/roles-permissions/store'
import auditlogs from '@src/views/apps/auditlog/store'

const rootReducer = {
  auth,
  users,
  companies,
  agreements,
  navbar,
  kanban,
  layout,
  invoice,
  dataTables,
  documentation,
  calendar,
  authentication,
  accountSetting,
  auditlogs,
  projects,
  proposals,
  tickets,
  alerts,
  products,
  usersList,
  permissions
}

export default rootReducer
