// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

const checkNull = (value) => {
  return !value || JSON.parse(value) === "null"
}

const authSelectedCompanies = checkNull(localStorage.getItem("selectedCompanies")) ? null : JSON.parse(localStorage.getItem("selectedCompanies"))
const authSelectedCompany = localStorage.getItem("selectedCompany") ? JSON.parse(localStorage.getItem("selectedCompany")) : null
const authCompanyFilter = localStorage.getItem("companyFilter") ? JSON.parse(localStorage.getItem("companyFilter")) : null

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    authentication: {
      selectedCompany: authSelectedCompany,
      selectedCompanies: authSelectedCompanies,
      companyFilter: authCompanyFilter
    }
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export { store }
