import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  projects: [
    {
      id: 4987,
      createdOn: '13 Dec 2019',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'tom@devnostic.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Devnostic'
      },
      status: 'active'
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/project/projects').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.projects.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(project => {
    if (String('paid').includes(queryLowered) && project.balance === 0) {
      return project.balance === 0
    } else {
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      return (
        (project.client.companyEmail.toLowerCase().includes(queryLowered) ||
          project.client.name.toLowerCase().includes(queryLowered) ||
          String(project.id).toLowerCase().includes(queryLowered) ||
          String(project.total).toLowerCase().includes(queryLowered) ||
          String(project.balance).toLowerCase().includes(queryLowered) ||
          project.dueDate.toLowerCase().includes(queryLowered)) &&
        project.projectStatus.toLowerCase() === (status.toLowerCase() || project.projectStatus.toLowerCase())
      )
    }
  })
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.projects,
      total: filteredData.length,
      projects: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/project\/projects\/\d+/).reply(config => {
  // // Get event id from URL
  const projectId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const projectIndex = data.projects.findIndex(e => e.id === projectId)
  const responseData = {
    project: data.projects[projectIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/project/delete').reply(config => {
  // Get project id from URL
  let projectId = config.id

  // Convert Id to number
  projectId = Number(projectId)

  const projectIndex = data.projects.findIndex(t => t.id === projectId)
  data.projects.splice(projectIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/project/clients').reply(() => {
  const clients = data.projects.map(project => project.client)
  return [200, clients.slice(0, 5)]
})
