// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`

export const getAllData = createAsyncThunk('appDocumentations/getAllData', async () => {
  const response = await axios.get(`${baseUrl}v1/ntiva/documentations`)
  return response.data
})

export const getData = createAsyncThunk('appDocumentations/getData', async params => {
  const response = await axios.get(encodeURI(`${baseAuthUrl}api/v1/liongard/systems/${params.id}/metrics`), params)
  const data = response.data.data
  const total = response.data.data.length
  const pageNum = parseInt(params.page) - 1
  const skipData = pageNum * params.perPage
  const size = params.perPage
  const documentations = []
  for (let index = 0; index < data.length; index++) {
    const documentation = data[index]
    if (index >= skipData && index < (skipData + size)) {
      documentations.push(documentation)
    }
  }
  return {
    params,
    data: documentations,
    totalPages: total,
    allData: data
  }
})

export const clearData = createAsyncThunk('appDocumentations/clearData',  () => {
  return null
})

export const loading = createAsyncThunk("appDocumentations/loading", (payload) => {
  return payload
})

export const appDocumentationsSlice = createSlice({
  name: 'appDocumentations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDocumentation: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedDocumentation = action.payload
        state.data = []
        state.total = 1
        state.allData = []
        state.isLoading = false
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appDocumentationsSlice.reducer
