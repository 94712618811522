// ** Icons Import
import { FileText, Users, BarChart, FilePlus, BarChart2, CheckSquare, CreditCard, Book, Calendar, Briefcase, HelpCircle } from 'react-feather'

export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <BarChart size={16} />,
    navLink: '/dashboard',
    resource: 'DASHBOARD',
    action: 'read'
  },
  {
    id: 'contacts',
    title: 'Contacts',
    icon: <Users size={16} />,
    navLink: '/apps/user/list',
    resource: 'CONTACTS',
    action: 'read'
  },
  // {
  //   id: 'agreements',
  //   title: 'Agreements',
  //   icon: <FileText size={16} />,
  //   navLink: '/apps/agreement/list',
  //   resource: 'AGREEMENTS',
  //   action: 'read'
  // },
  // {
  //   id: 'projects',
  //   title: 'Projects',
  //   icon: <CheckSquare size={16} />,
  //   navLink: '/apps/projects/list',
  //   resource: 'PROJECTS',
  //   action: 'read'
  // },
  {
    id: 'tickets',
    title: 'Tickets',
    icon: <FilePlus size={16} />,
    navLink: '/apps/tickets',
    resource: 'TICKETS',
    action: 'read'
  },
  // {
  //   id: 'proposals',
  //   title: 'Proposals',
  //   icon: <Briefcase size={16} />,
  //   navLink: '/apps/proposals',
  //   resource: 'USER',
  //   action: 'read'
  // },
  {
    id: 'invoices',
    title: 'Invoices',
    icon: <CreditCard size={16} />,
    navLink: '/apps/invoice/list',
    resource: 'INVOICES',
    action: 'read'
  },
  // {
  //   id: 'documentation',
  //   title: 'Documentation',
  //   icon: <Book size={16} />,
  //   navLink: '/apps/documentation',
  //   resource: 'COMPANYDOCS',
  //   action: 'read'
  // },
  // {
  //   id: 'scheduler',
  //   title: 'Scheduler',
  //   icon: <Calendar size={16} />,
  //   navLink: '/apps/calendar',
  //   resource: 'SCHEDULER',
  //   action: 'read'
  // },
  {
    id: 'help',
    title: 'Contact Us',
    icon: <HelpCircle size={16} />,
    navLink: '/pages/faq'
  }
]
