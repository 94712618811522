// ** Icons Import
import { Shield, Settings, List, User, ShoppingBag, Code, PlayCircle, Tool, UserCheck } from 'react-feather'

export default [
    {
        header: 'SETTINGS',
        resource: 'SYSTEMSETTINGS',
        action: 'read'
    }, 
    {
      id: 'security',
      title: 'Security',
      icon: <Shield size={20} />,
      children: [
        {
          id: 'user',
          title: 'Users',
          icon: <User size={20} />,
          navLink: '/apps/users/list',
          resource: 'USERS',
          action: 'read'
      },
      {
          id: 'roles',
          title: 'Roles',
          icon: <UserCheck size={20} />,
          navLink: '/apps/roles',
          resource: 'PERMISSIONS',
          action: 'read'
        },
        {
            id: 'permissions',
            title: 'Permissions',
            icon: <Tool size={20} />,
            navLink: '/apps/permissions',
            resource: 'PERMISSIONS',
            action: 'read'
          }
      ]
    },
      // {
      //     id: 'products',
      //     title: 'Products',
      //     icon: <ShoppingBag size={20} />,
      //     navLink: '/apps/product/list',
      //     resource: 'PRODUCTS',
      //     action: 'manage'
      // },
      // {
      //     id: 'audit',
      //     title: 'Audit Log',
      //     icon: <List size={20} />,
      //     navLink: '/apps/auditlog/list',
      //     resource: 'AUDITLOG',
      //     action: 'read'
      // },
      {
          id: 'system-settings',
          title: 'System Settings',
          icon: <Settings size={20} />,
          navLink: '/pages/system-settings',
          resource: 'SYSTEMSETTINGS',
          action: 'manage'
      }
]
