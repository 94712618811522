// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`
export const getAllData = createAsyncThunk('appProjects/getAllData', async () => {
  const response = await axios.get(`${baseUrl}v1/ntiva/projects`)
  return response.data
})

export const getData = createAsyncThunk('appProjects/getData', async params => {
  const response = await axios.get(`${baseUrl}v1/ntiva/projects?sort=${params.sort}&&sortColumn=${params.sortColumn}`, params)
  const data = response.data.data
  const total = response.data.data.length
  const pageNum = parseInt(params.page) - 1
  const skipData = pageNum * params.perPage
  const size = params.perPage
  const projects = []
  for (let index = 0; index < data.length; index++) {
    const project = data[index]
    if (index >= skipData && index < (skipData + size)) {
      projects.push(project)
    }
  }
  return {
    params,
    data: projects,
    totalPages: total,
    allData: data
  }
})

export const getProject = createAsyncThunk('appProjects/getProject', async id => {
  const response = await axios.get(`${baseUrl}v1/project/${id}`, { id })
  return response.data.data
})

export const getProjectWorkItems = createAsyncThunk('appProjects/getProjectWorkItems', async id => {
  const response = await axios.get(`${baseAuthUrl}api/v1/project/${id}/workitems?fields=id,summary,board/name,status/name,workRole/name,project/name,phase/name,wbsCode,company/name,company/id,siteName,contactName,contactPhoneNumber,contactEmailAddress,type/name,priority/name,budgetHours,closedDate,closedFlag,budgetHours,actualHours,department/name,_info/lastUpdated`, { id })
  return response.data.data
})

export const addProject = createAsyncThunk('appProjects/addProject', async (project, { dispatch, getState }) => {
  await axios.post('/apps/project/add-project', project)
  await dispatch(getData(getState().projects.params))
  await dispatch(getAllData())
  return project
})

export const deleteProject = createAsyncThunk('appProjects/deleteProject', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/project/delete', { id })
  await dispatch(getData(getState().projects.params))
  await dispatch(getAllData())
  return id
})

export const agreementEnabler = createAsyncThunk('appUsers/agreementEnabler', async(configuration, { dispatch }) => {
  const response = await axios.post(`${baseUrl}v1/configuration`, configuration)
  await dispatch(getProject(configuration.id))
  return response
})

export const getAddProjects = createAsyncThunk('appUsers/getAddProjects', async (id) => {
  const response = await axios.get(`${baseUrl}v1/ntiva/contradictory/projects/${id}`)
  return response.data
})

export const postAddProjects = createAsyncThunk('appUsers/postAddProjects', async (data, {dispatch}) => {
  const response = await axios.post(`${baseUrl}v1/ntiva/contradictory/projects/update`, data)
  await dispatch(getProject(data.id))
  return response.data
})
export const clearData = createAsyncThunk('appProjects/clearData',  () => {
  return null
})
export const loading = createAsyncThunk(
  "appProjects/loading", (payload) => {
    return payload
  }
)

export const appProjectsSlice = createSlice({
  name: 'appProjects',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedProject: null,
    addProjects: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.selectedProject = action.payload
      })
      .addCase(getAddProjects.fulfilled, (state, action) => {
        state.addProjects = action.payload.data
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedProject = action.payload
        state.data = []
        state.total = 1
        state.allData = []
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appProjectsSlice.reducer
