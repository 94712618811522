import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  agreements: [
    {
      id: 1,
      name: 'Agreement 1',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 2,
      name: 'Agreement 2',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 3,
      name: 'Agreement 3',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 4,
      name: 'Agreement 4',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 5,
      name: 'Agreement 5',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 6,
      name: 'Agreement 6',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 7,
      name: 'Agreement 7',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 8,
      name: 'Agreement 8',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 9,
      name: 'Agreement 9',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 10,
      name: 'Agreement 10',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 11,
      name: 'Agreement 11',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    },
    {
      id: 12,
      name: 'Agreement 12',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      status: 'inactive',
      avatarColor: 'light-primary'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/agreements/list/all-data').reply(200, data.agreements)

// POST: Add new agreement
mock.onPost('/apps/agreement/add-agreement').reply(config => {
  // Get event from post data
  const agreement = JSON.parse(config.data)
  const highestValue = data.agreements.reduce((a, b) => (a.id > b.id ? a : b)).id

  agreement.id = highestValue + 1

  data.agreements.push(agreement)

  return [201, { agreement }]
})

// GET Updated DATA
mock.onGet('/api/agreements/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.agreements.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    agreement =>
      (agreement.email.toLowerCase().includes(queryLowered) ||
        agreement.fullName.toLowerCase().includes(queryLowered) ||
        agreement.billing.toLowerCase().includes(queryLowered)) &&
      agreement.role === (role || agreement.role) &&
      agreement.currentPlan === (currentPlan || agreement.currentPlan) &&
      agreement.status === (status || agreement.status)
  )
  /* eslint-enable  */


  return [
    200,
    {
      total: filteredData.length,
      agreements: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/agreements/agreement').reply(config => {
  const { id } = config
  const agreement = data.agreements.find(i => i.id === id)
  return [200, { agreement }]
})

// DELETE: Deletes Agreement
mock.onDelete('/apps/agreement/delete').reply(config => {
  // Get agreement id from URL
  let agreementId = config.id

  // Convert Id to number
  agreementId = Number(agreementId)

  const agreementIndex = data.agreements.findIndex(t => t.id === agreementId)
  data.agreements.splice(agreementIndex, 1)

  return [200]
})
