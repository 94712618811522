// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`

export const getData = createAsyncThunk('permissions/getData', async params => {
  const response = await axios.get('/apps/permissions/data', { params })
  return {
    total: response.data.total,
    params: response.data.params,
    allData: response.data.allData,
    data: response.data.permissions
  }
})

export const getRoles = createAsyncThunk('permissions/getRoles', async () => {
  const response = await axios.get(`${baseAuthUrl}api/v1/roles`)
  return response.data.data
})

export const getRole = createAsyncThunk('permissions/getRole', async (id) => {
  const response = await axios.get(`${baseAuthUrl}api/v1/roles/${id}`)
  return response.data.data
})

export const getPermissions = createAsyncThunk('permissions/getPermissions', async () => {
  const response = await axios.get(`${baseAuthUrl}api/v1/permissions`)
  return response.data.data
})

export const getAvailablePermissions = createAsyncThunk('permissions/getAvailablePermissions', async id => {
  const response = await axios.get(`${baseAuthUrl}api/v1/roles/${id}/available_permissions`)
  return response.data.data
})

export const addPermissionToRole = createAsyncThunk('permissions/addPermissionToRole', async (role, { dispatch }) => {
    await axios.post(`${baseAuthUrl}api/v1/roles/permission`, role)
    await dispatch(getRole(role.role_id))
    return role
  }
)

export const addRole = createAsyncThunk('permissions/addRole', async (role, { dispatch }) => {
    await axios.post(`${baseAuthUrl}api/v1/roles`, role)
    await dispatch(getRoles())
    return role
  }
)

export const updateRole = createAsyncThunk('permissions/updateRole', async (role, { dispatch }) => {
    await axios.put(`${baseAuthUrl}api/v1/roles`, role)
    await dispatch(getRoles())
    return role
  }
)

export const addPermission = createAsyncThunk('permissions/addPermission', async (permission, { dispatch }) => {
  await axios.post(`${baseAuthUrl}api/v1/permissions`, permission)
  await dispatch(getPermissions())
    return permission
  }
)

export const updatePermission = createAsyncThunk(
  'permissions/updatePermission',
  async ({ id, name }, { dispatch, getState }) => {
    await axios.post('/apps/permissions/update-permission', { id, name })
    await dispatch(getData(getState().permissions.params))
    return { id, name }
  }
)

export const deletePermission = createAsyncThunk('permissions/deletePermission', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/permissions/delete', { id })
  await dispatch(getData(getState().permissions.params))
  return id
})

export const deleteRole = createAsyncThunk('permissions/deleteRole', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/permissions/delete', { id })
  await dispatch(getData(getState().permissions.params))
  return id
})

export const loading = createAsyncThunk(
  "permissions/loading", (payload) => {
    return payload
  }
)

export const clearData = createAsyncThunk('permissions/clearData',  () => {
  return null
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    data: [],
    roles: [],
    allPermissions: [],
    total: 1,
    params: {},
    allData: [],
    selectedRole: null,
    selectedPermission: null,
    isLoading: false,
    cached: false
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectRole: (state, action) => {
      if (action.payload === null) {
        state.selectedRole = null
      } else {
        state.selectedRole = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.total
      state.params = action.payload.params
      state.allData = action.payload.allData
    })
    .addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload
    })
    .addCase(getPermissions.fulfilled, (state, action) => {
      state.allPermissions = action.payload
    })
    .addCase(getRole.fulfilled, (state, action) => {
      state.selectedRole = action.payload
    })
    .addCase(clearData.fulfilled, (state, action) => {
      state.allPermissions = []
      state.roles = []
      state.data = []
      state.total = 1
      state.allData = []
      state.selectedRole = action.payload
      state.selectedPermission = action.payload
    })
    .addCase(loading.fulfilled, (state, action) => {
      state.isLoading = action.payload
    })
  }
})

export const { selectPermission, selectRole } = permissionsSlice.actions

export default permissionsSlice.reducer
