// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`
export const getAllData = createAsyncThunk('appCompanies/getAllData', async () => {
  const response = await axios.get(`${baseUrl}v1/ntiva/companies`)
  return response.data
})

export const getData = createAsyncThunk('appCompanies/getData', async params => {
  const response = await axios.get(`${baseUrl}v1/ntiva/companies?sort=${params.sort}&&sortColumn=${params.sortColumn}`, params)
  const data = response.data
  const total = response.data.length
  const pageNum = parseInt(params.page) - 1
  const skipData = pageNum * params.perPage
  const size = params.perPage
  const companies = []
  for (let index = 0; index < data.length; index++) {
    const company = data[index]
    if (index >= skipData && index < (skipData + size)) {
      companies.push(company)
    }
  }

  return {
    params,
    data: companies,
    allData: data,
    totalPages: total
  }
})

export const getAccountManager = createAsyncThunk('appCompanies/getAccountManager', async id => {
  const response = await axios.get(`${baseAuthUrl}api/v1/company/${id}/accountManager`)
  return response.data
})

export const getBillingContact = createAsyncThunk('appCompanies/getBillingContact', async id => {
  const response = await axios.get(`${baseAuthUrl}api/v1/company/${id}/billingContact`)
  return response.data
})

export const getAlerts = createAsyncThunk('appCompanies/getAlerts', async () => {
  const response = await axios.get(`${baseAuthUrl}api/v1/alerts`)
  return response.data
})

export const getCSat = createAsyncThunk('appCompanies/getCSat', async id => {
  const response = await axios.get(`${baseAuthUrl}api/v1/csat/${id}`)
  return response.data
})

export const getSites = createAsyncThunk('appCompanies/getSites', async id => {
  const response = await axios.get(`${baseAuthUrl}api/v1/company/${id}/sites`)
  return response.data
})

export const getFilterData = createAsyncThunk('appCompanies/getFilterData', async params => {
  return {
    params
  }
})

export const getCompany = createAsyncThunk('appCompanies/getCompany', async id => {
  const response = await axios.get(`${baseUrl}v1/ntiva/company/${id}`)
  return response.data
})

export const addCompany = createAsyncThunk('appCompanies/addCompany', async (company, { dispatch, getState }) => {
  await axios.post('/apps/company/add-company', company)
  await dispatch(getData(getState().companies.params))
  await dispatch(getAllData())
  return company
})

export const deleteCompany = createAsyncThunk('appCompanies/deleteCompany', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/company/delete', { id })
  await dispatch(getData(getState().companies.params))
  await dispatch(getAllData())
  return id
})
export const clearData = createAsyncThunk('appCompanies/clearData',  () => {
  return null
})
export const loading = createAsyncThunk(
  "appCompanies/loading", (payload) => {
    return payload
  }
)

export const appCompaniesSlice = createSlice({
  name: 'appCompanies',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    alerts: [],
    csat: {},
    accountManager: {},
    billingContact: {},
    selectedCompany: null,
    sites: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.sites = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getFilterData.fulfilled, (state, action) => {
        state.params = action.payload.params
      })
      .addCase(getAccountManager.fulfilled, (state, action) => {
        state.accountManager = action.payload.data
      })
      .addCase(getBillingContact.fulfilled, (state, action) => {
        state.billingContact = action.payload.data
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.alerts = action.payload.data
      })
      .addCase(getCSat.fulfilled, (state, action) => {
        state.csat = action.payload.data
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedCompany = action.payload.data
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedCompany = action.payload
        state.csat = {}
        state.accountManager = {}
        state.billingContact = {}
        state.alerts = []
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appCompaniesSlice.reducer
